import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./slices/authSlice";
import signupReducer from "./slices/signupSlice";
import messageReducer from "./slices/message";
import imageReducer from "../store/slices/imageSlice";
import clientReducer from "../store/slices/clientSlice";
import clientphotoReducer from "../store/slices/clientphotoSlice";
import clientdocumentReducer from "../store/slices/clientdocumentSlice";
import clientnoteReducer from "../store/slices/clientnoteSlice";
import supplierReducer from "../store/slices/supplierSlice";
import productReducer from "../store/slices/productSlice";
import categoryReducer from "../store/slices/categorySlice";
import serviceReducer from "../store/slices/serviceSlice";
import taxReducer from "../store/slices/taxSlice";
import socketReducer from "../store/slices/socketSlice";
import staffReducer from "../store/slices/staffSlice";
import pricetierReducer from "../store/slices/pricetierSlice";
import rosterReducer from "../store/slices/rosterSlice";
import salonReducer from "./slices/salonSlice";
import salonmoduleReducer from "./slices/salonmoduleSlice";
import salonaccessReducer from "../store/slices/salonaccessSlice";
import busytimeReducer from "../store/slices/busytimeSlice";
import appointmentReducer from "../store/slices/appointmentSlice";
import calendarReducer from "../store/slices/calendarSlice";
import voucherReducer from "../store/slices/voucherSlice";
import saleReducer from "../store/slices/saleSlice";
import membershipReducer from "../store/slices/membershipSlice";
import subscriptionReducer from "../store/slices/subscriptionSlice";
import stripeReducer from "../store/slices/stripeSlice";
import clientmembershipReducer from "../store/slices/clientmembershipSlice";
import clientsubscriptionReducer from "../store/slices/clientsubscriptionSlice";
import settingReducer from "../store/slices/settingSlice";
import businessReducer from "../store/slices/businessSlice";
import clientinvoiceReducer from "../store/slices/clientinvoiceSlice";
import clientvoucherReducer from "../store/slices/clientvoucherSlice";
import closedateReducer from "../store/slices/closedateSlice";
import dashboardReducer from "../store/slices/dashboardSlice";
import cancellationreasonReducer from "../store/slices/cancellationreasonSlice";
import formReducer from "./slices/formSlice";
import reportReducer from "../store/slices/reportSlice";
import bookinglinkReducer from "./slices/bookinglinkSlice";
import marketingReducer from "../store/slices/marketingSlice";
import birthdayCampaignReducer from "../store/slices/birthdayCampaignSlice";
import appointmentCampaignReducer from "../store/slices/appointmentCampaignSlice";
import reviewCampaignReducer from "../store/slices/reviewCampaignSlice";
import notificationReducer from "../store/slices/notificationSlice";
import custompagesReducer from "../store/slices/custompagesSlice";
import canvaTemplateReducer from "../store/slices/canvaTemplateSlice";
import oneoffCampaignReducer from "../store/slices/oneoffCampaignSlice";
import bookingReducer from "../store/slices/bookingSlice";
import academyReducer from "../store/slices/academySlice";
import academycategoryReducer from "../store/slices/academyCategorySlice";
import academyarticleReducer from "../store/slices/academyArticleSlice";
import everydaymarketingReducer from "../store/slices/everydaymarketingsSlice";
import commonReducer from "../store/slices/commonSlice";
import mailchimpReducer from "../store/slices/mailchimpSlice";
import headerReducer from "../store/slices/headerSlice";
import chartReducer from "../store/slices/chartSlice";
import clientpastinvoiceReducer from "./slices/clientappointmentinvoiceSlice";
import clientproductReducer from "./slices/clientproductSlice";
import clientformReducer from "./slices/clientformSlice";
import packageReducer from "./slices/packageSlice";
import credentialReducer from "./slices/credentialchangeSlice";
import packageplanReducer from "./slices/packageplandetailsSlice";
import smsusesReducer from "./slices/smsusesSlice";
import billingReducer from "./slices/billingAddressSlice";
import salonsubscriptionReducer from "./slices/salonsubscriptionSlice";
import smtpReducer from "./slices/smtpSlice";
import printerReducer from "./slices/printerSlice";
import creditcardReducer from "./slices/creditcardUpdateSlice";
import addonReducer from "./slices/addonSlice";
import addanotherReducer from "./slices/addanotherSlice";
import consultationformReducer from "./slices/consultationformlinkSlice";
import StripoEmailReducer from "./slices/stripoemailbuilderSlice";
import SidebarToggleReducer from "./slices/sidebarToggleSlice";
import generalloginReducer from "./slices/generalLoginSlice";
import usermoduleReducer from "./slices/usermoduleSlice";
import stafftargetReducer from "./slices/staffTargetSlice";
import dropdownReducer from "./slices/dropdownToggleSlice";
import zoomcontrolReducer from "./slices/zoomControlSlice";
import emailverificationReducer from "./slices/clientsideEmailverificationSlice";
import clientchatReducer from "./slices/clientChatSlice";
import resourceReducer from "./slices/resourceSlice";
import staffAvailabilityReducer from "./slices/staffAvailabilitySlice";
import bookingSummaryReducer from './slices/bookingSummarySlice';
//-----------------------|| COMBINE REDUCER ||-----------------------//
// const rootPersistConfig = {
//   key: "root",
//   timeout: 500,
//   storage,
//   // blacklist: ["message", "image", 'isView']
// };

const reducer = combineReducers({
  auth: persistReducer(
    {
      key: "auth",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
    },
    authReducer,
  ),
  signup: persistReducer(
    {
      key: "signup",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    signupReducer,
  ),
  message: messageReducer,
  common: persistReducer(
    {
      key: "common",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    commonReducer,
  ),
  salon: persistReducer(
    {
      key: "salon",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    salonReducer,
  ),
  dashboard: persistReducer(
    {
      key: "dashboard",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    dashboardReducer,
  ),
  business: persistReducer(
    {
      key: "business",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    businessReducer,
  ),
  client: persistReducer(
    {
      key: "client",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    clientReducer,
  ),
  clientphoto: persistReducer(
    {
      key: "clientphoto",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientphotoReducer,
  ),
  clientdocument: persistReducer(
    {
      key: "clientdocument",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientdocumentReducer,
  ),
  clientnote: persistReducer(
    {
      key: "clientnote",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientnoteReducer,
  ),
  clientmembership: persistReducer(
    {
      key: "clientmembership",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientmembershipReducer,
  ),
  clientsubscription: persistReducer(
    {
      key: "clientsubscription",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientsubscriptionReducer,
  ),
  clientinvoice: persistReducer(
    {
      key: "clientinvoice",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientinvoiceReducer,
  ),
  clientvoucher: persistReducer(
    {
      key: "clientvoucher",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientvoucherReducer,
  ),
  product: persistReducer(
    {
      key: "product",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    productReducer,
  ),
  supplier: persistReducer(
    {
      key: "supplier",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    supplierReducer,
  ),
  service: persistReducer(
    {
      key: "service",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    serviceReducer,
  ),
  category: persistReducer(
    {
      key: "category",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    categoryReducer,
  ),
  staff: persistReducer(
    {
      key: "staff",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    staffReducer,
  ),
  pricetier: persistReducer(
    {
      key: "pricetier",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    pricetierReducer,
  ),
  roster: persistReducer(
    {
      key: "roster",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    rosterReducer,
  ),
  salonmodule: persistReducer(
    {
      key: "salonmodule",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    salonmoduleReducer,
  ),
  salonaccess: persistReducer(
    {
      key: "salonaccess",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    salonaccessReducer,
  ),
  busytime: persistReducer(
    {
      key: "busytime",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    busytimeReducer,
  ),
  appointment: persistReducer(
    {
      key: "appointment",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    appointmentReducer,
  ),
  calendar: persistReducer(
    {
      key: "calendar",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    calendarReducer,
  ),
  voucher: persistReducer(
    {
      key: "voucher",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    voucherReducer,
  ),
  sale: persistReducer(
    {
      key: "sale",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView"],
    },
    saleReducer,
  ),
  membership: persistReducer(
    {
      key: "membership",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    membershipReducer,
  ),
  subscription: persistReducer(
    {
      key: "subscription",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    subscriptionReducer,
  ),
  stripe: persistReducer(
    {
      key: "stripe",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    stripeReducer,
  ),
  setting: persistReducer(
    {
      key: "setting",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isTabView", "isSetting"],
    },
    settingReducer,
  ),
  closedate: persistReducer(
    {
      key: "closedate",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    closedateReducer,
  ),
  cancellationreason: persistReducer(
    {
      key: "cancellationreason",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    cancellationreasonReducer,
  ),
  form: persistReducer(
    {
      key: "form",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    formReducer,
  ),
  report: persistReducer(
    {
      key: "report",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    reportReducer,
  ),
  bookinglink: persistReducer(
    {
      key: "bookinglink",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    bookinglinkReducer,
  ),
  marketing: persistReducer(
    {
      key: "marketing",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isSelectedView", "isTabView"],
    },
    marketingReducer,
  ),
  everydaymarketing: persistReducer(
    {
      key: "everydaymarketing",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isSelectedView", "isTabView"],
    },
    everydaymarketingReducer,
  ),
  birthdaycampaign: persistReducer(
    {
      key: "birthdaycampaign",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    birthdayCampaignReducer,
  ),
  appointmentcampaign: persistReducer(
    {
      key: "appointmentcampaign",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    appointmentCampaignReducer,
  ),
  reviewcampaign: persistReducer(
    {
      key: "reviewcampaign",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    reviewCampaignReducer,
  ),
  notification: persistReducer(
    {
      key: "notification",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    notificationReducer,
  ),
  custompages: persistReducer(
    {
      key: "custompages",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isPages"],
    },
    custompagesReducer,
  ),
  canvatemplate: persistReducer(
    {
      key: "canvatemplate",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isPages"],
    },
    canvaTemplateReducer,
  ),
  oneoffcampaign: persistReducer(
    {
      key: "oneoffcampaign",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    oneoffCampaignReducer,
  ),
  booking: persistReducer(
    {
      key: "booking",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    bookingReducer,
  ),
  academy: persistReducer(
    {
      key: "academy",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    academyReducer,
  ),
  academycategory: persistReducer(
    {
      key: "academy",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    academycategoryReducer,
  ),
  academyarticle: persistReducer(
    {
      key: "academy",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    academyarticleReducer,
  ),
  mailchimp: persistReducer(
    {
      key: "mailchimp",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    mailchimpReducer,
  ),
  header: persistReducer(
    {
      key: "header",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    headerReducer,
  ),
  chart: persistReducer(
    {
      key: "chart",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    chartReducer,
  ),
  clientpastinvoice: persistReducer(
    {
      key: "chart",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientpastinvoiceReducer,
  ),
  package: persistReducer(
    {
      key: "package",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: ["isPackageManageData", "isPackageName"],
    },
    packageReducer,
  ),
  credential: persistReducer(
    {
      key: "credential",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    credentialReducer,
  ),
  packageplan: persistReducer(
    {
      key: "packageplan",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    packageplanReducer,
  ),
  smsuses: persistReducer(
    {
      key: "smsuses",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    smsusesReducer,
  ),
  billing: persistReducer(
    {
      key: "billing",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    billingReducer,
  ),
  salonsubscription: persistReducer(
    {
      key: "salonsubscription",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    salonsubscriptionReducer,
  ),
  smtp: persistReducer(
    {
      key: "smtp",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    smtpReducer,
  ),
  printer: persistReducer(
    {
      key: "printer",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    printerReducer,
  ),
  creditcard: persistReducer(
    {
      key: "creditcard",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    creditcardReducer,
  ),
  addon: persistReducer(
    {
      key: "addon",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    addonReducer,
  ),
  addanother: persistReducer(
    {
      key: "addanother",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    addanotherReducer,
  ),
  clientproduct: persistReducer(
    {
      key: "clientproduct",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientproductReducer,
  ),
  clientform: persistReducer(
    {
      key: "clientform",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientformReducer,
  ),
  consultationform: persistReducer(
    {
      key: "consultationform",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    consultationformReducer,
  ),
  stripoemail: persistReducer(
    {
      key: "stripoemail",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    StripoEmailReducer,
  ),
  sidebar: persistReducer(
    {
      key: "sidebar",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    SidebarToggleReducer,
  ),
  generallogin: persistReducer(
    {
      key: "generallogin",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    generalloginReducer,
  ),
  usermodule: persistReducer(
    {
      key: "usermodule",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    usermoduleReducer,
  ),
  stafftarget: persistReducer(
    {
      key: "stafftarget",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    stafftargetReducer,
  ),
  dropdown: persistReducer(
    {
      key: "dropdown",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    dropdownReducer,
  ),
  zoomcontrol: persistReducer(
    {
      key: "zoomcontrol",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
      // whitelist: ["height"],
    },
    zoomcontrolReducer,
  ),
  emailverification: persistReducer(
    {
      key: "emailverification",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    emailverificationReducer,
  ),
  clientchat: persistReducer(
    {
      key: "clientchat",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    clientchatReducer,
  ),
  resource: persistReducer(
    {
      key: "resource",
      storage,
      keyPrefix: "salon-",
      debug: false,
      timeout: 20000,
      whitelist: [],
    },
    resourceReducer,
  ),
  tax: taxReducer,
  socket: socketReducer,
  image: imageReducer,
  staffAvailability: staffAvailabilityReducer,
  bookingSummary: bookingSummaryReducer,
});

export default reducer;
