import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const ApiStatus = (props) => {
  const actionType = props.actionType;
  // const SalonModuleApiStatus = useSelector((state) => state.salonmodule.isApiStatus);
  const UserModuleApiStatus = useSelector((state) => state.usermodule.isApiStatus);
  const DashboardApiStatus = useSelector((state) => state.dashboard.isApiStatus);
  const staffApiStatus = useSelector((state) => state.staff.isApiStatus);
  const rosterApiStatus = useSelector((state) => state.roster.isApiStatus);
  const pricetierApiStatus = useSelector((state) => state.pricetier.isApiStatus);
  const categoryApiStatus = useSelector((state) => state.category.isApiStatus);
  const serviceApiStatus = useSelector((state) => state.service.isApiStatus);
  const membershipApiStatus = useSelector((state) => state.membership.isApiStatus);
  const ClientApiStatus = useSelector((state) => state.client.isApiStatus);
  const appointmentcampaignApiStatus = useSelector((state) => state.appointmentcampaign.isApiStatus);
  const birthdaycampaignApiStatus = useSelector((state) => state.birthdaycampaign.isApiStatus);
  const reviewcampaignApiStatus = useSelector((state) => state.reviewcampaign.isApiStatus);
  const bookingApiStatus = useSelector((state) => state.booking.isApiStatus);
  const AcademyApiStatus = useSelector((state) => state.academycategory.isApiStatus);
  const subAcademyApiStatus = useSelector((state) => state.academyarticle.isApiStatus);
  const everydaymarketApiStatus = useSelector((state) => state.everydaymarketing.isApiStatus);
  const subeverydaymarketApiStatus = useSelector((state) => state.everydaymarketing.isApiStatus);
  const ProductApiStatus = useSelector((state) => state.product.isApiStatus);
  const supplierGridViewApiStatus = useSelector((state) => state.supplier.isApiStatus);
  const VoucherGridViewApiStatus = useSelector((state) => state.voucher.isApiStatus);
  const clientproductApiStatus = useSelector((state) => state.clientproduct.isApiStatus);
  const clientappointmentApiStatus = useSelector((state) => state.appointment.isApiStatus);
  const clientvoucherApiStatus = useSelector((state) => state.clientvoucher.isApiStatus);
  const clientsubscriptionApiStatus = useSelector((state) => state.clientsubscription.isApiStatus);
  const clientmembershipApiStatus = useSelector((state) => state.clientmembership.isApiStatus);
  const clientnotificationApiStatus = useSelector((state) => state.notification.isApiStatus);
  const clientinvoiceApiStatus = useSelector((state) => state.clientinvoice.isApiStatus);
  //////////////////////////////////////////////////////////////////
  const SaleInvoiceApiStatus = useSelector((state) => state.sale.isApiStatus);
  //////////////////////////////////////
  const clientformApiStatus = useSelector((state) => state.clientform.isApiStatus);
  const cancellationApiStatus = useSelector((state) => state.cancellationreason.isApiStatus);
  const consultationFormApiStatus = useSelector((state) => state.form.isApiStatus);
  const closedateApiStatus = useSelector((state) => state.closedate.isApiStatus);
  const bookinglinkcategoryApiStatus = useSelector((state) => state.booking.isApiStatus);
  const chatApiStatus = useSelector((state) => state.clientchat.isApiStatus);
  const StaffTargetApiStatus = useSelector((state) => state.stafftarget.isApiStatus);
  const subscriptionApiStatus = useSelector((state) => state.subscription.isApiStatus);
  const notificationApiStatus = useSelector((state) => state.notification.isApiStatus);
  const resourceApiStatus = useSelector((state) => state.resource.isApiStatus);
  const memberShipOption = useSelector((state) => state.booking.isApiStatus);

  if (UserModuleApiStatus && UserModuleApiStatus.SalonModule === "loading") {
    if (actionType === "sidebar_menu") {
      return Array.apply(0, Array(10)).map(function (x, i) {
        return (
          <li key={i}>
            <NavLink to={"#"} className="placeholder-glow" data-bs-toggle="tooltip" data-bs-placement="right" title="">
              <span className="icon placeholder"></span>
              <span className="d-lg-none ps-3"></span>
            </NavLink>
          </li>
        );
      });
    }
  }
  if (StaffTargetApiStatus && StaffTargetApiStatus.stafftargetGridViewApi === "loading") {
    if (actionType === "stafftargetlistviews") {
      return (
        <>
          {Array.apply(0, Array(10)).map(function (x, i) {
            return (
              <div className="col-12" key={i}>
                <div className="box-image-cover w-100 mx-0 p-md-4 p-3 mb-md-4 mb-3 text-start placeholder-glow">
                  <div className="row align-items-center">
                    <div className="col-10 mb-md-0 mb-2">
                      <div className="d-flex align-items-center">
                        <div className="text-center">
                          <span className="placeholder rounded-circle" style={{ width: "80px", height: "80px" }}></span>
                        </div>
                        <div className="ps-3 col-md-12">
                          <span className="placeholder w-100"></span>
                          <span className="placeholder w-100"></span>
                          <span className="placeholder w-100"></span>
                          <span className="placeholder w-100"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  // if (StaffTargetApiStatus && StaffTargetApiStatus.stafftargetGridViewApi === "loading") {
  if (actionType === "staff_addon_service_loading") {
    return (
      <ul className="list-unstyled mb-0 p-0 m-0">
        {Array.apply(0, Array(10)).map(function (x, i) {
          return (
            <div className="col-12 mb-2" key={i}>
              <div className="placeholder-glow">
                <div className="row align-items-center">
                  <div className="col-10 mb-md-0 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="col-1" style={{ width: "18px", marginRight: "8px" }}>
                        <span className="placeholder w-100" ></span>
                      </div>
                      <div className="col-11">
                        <span className="placeholder w-100"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </ul>
    );
  }
  // }
  if (UserModuleApiStatus && UserModuleApiStatus.usermoduleAccessViewApi === "loading") {
    if (actionType === "usermodule_access") {
      return (
        <ul className="list-unstyled mb-3 ps-lg-4 ps-3 row">
          {Array.apply(0, Array(5)).map(function (x, i) {
            return (
              <li key={i} className="li col-md-2 placeholder-glow">
                <span className="placeholder col-12"></span>
                <ul className="list-unstyled mb-0 ps-lg-4 ps-3">
                  {Array.apply(0, Array(5)).map(function (y, j) {
                    return (
                      <li key={i + "" + j}>
                        <div className="">
                          <span className="placeholder col-12"></span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      );
    }

    if (actionType === "dashboard_menu") {
      return Array.apply(0, Array(7)).map(function (x, i) {
        return (
          <div className="box-col col-auto mb-md-4 mb-3 placeholder-glow" key={i}>
            <div className="box-common text-center bg-white px-1 pt-4 pb-3 d-flex flex-column justify-content-between">
              <NavLink to={"#"} data-bs-toggle="tooltip" className="mb-2" data-bs-placement="right" title={""}>
                <div className="mb-2">
                  <span className="placeholder rounded-circle p-4"></span>
                </div>
                <span className="d-block fw-semibold">
                  <span className="placeholder col-12"></span>
                </span>
              </NavLink>
            </div>
          </div>
        );
      });
    }
  }

  if ((staffApiStatus && staffApiStatus.staffGridViewApi === "loading") || (ClientApiStatus && ClientApiStatus.ClientGridViewApi === "loading") || (subscriptionApiStatus && subscriptionApiStatus.SubscriptionGridViewApi === "loading")) {
    if (actionType === "staff" || actionType === "client" || actionType === "subscriptionLoads") {
      return Array.apply(0, Array(50)).map(function (x, i) {
        return (
          <a key={i} className="box-image-cover cursor-pointer placeholder-glow" id="addstaff-member-link">
            <div className="tabs-image">
              <span className="placeholder rounded-circle" style={{ width: "130px", height: "130px" }}></span>
            </div>
            <div className="image-content">
              <h5 className="placeholder w-100"></h5>
            </div>
          </a>
        );
      });
    }
  }

  if (ClientApiStatus && ClientApiStatus.ClientListViewApi === "loading") {
    if (actionType === "clientlist") {
      return Array.apply(0, Array(50)).map(function (x, i) {
        return (
          <div className="table-responsive bg-white maxwidth-auto" key={i}>
            <table className="table mb-0">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      });
    }
  }

  if (resourceApiStatus && resourceApiStatus.resourceGridViewApi === "loading") {
    if (actionType === "ResourceListLoadings") {
      return (
        <div className="table-responsive bg-white maxwidth-auto m-5 rounded">
          <table className="table mb-0">
            <tbody className="">
              {Array.apply(0, Array(12)).map(function (x, i) {
                return (
                  <tr className="placeholder-glow" key={i}>
                    {Array.apply(0, Array(10)).map(function (y, j) {
                      return (
                        <td className="text-center" align="center" key={i + "" + j} style={{ backgroundColor: i % 2 === 1 ? "#f9f6f4" : '#EEE' }}>
                          <span className="placeholder w-100"></span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }

  if (rosterApiStatus && (rosterApiStatus.DefaultStaffHourApi === "loading" || rosterApiStatus.rosterListViewApi === "loading")) {
    if (actionType === "roster") {
      return Array.apply(0, Array(50)).map(function (x, i) {
        return (
          <tr className="placeholder-glow" key={i}>
            <td>
              <span className="placeholder w-100"></span>
            </td>
            {Array.apply(0, Array(7)).map(function (y, j) {
              return (
                <td className="text-center" align="center" key={i + "" + j}>
                  <span className="placeholder w-100"></span>
                </td>
              );
            })}
          </tr>
        );
      });
    }
  }

  if (pricetierApiStatus && pricetierApiStatus.pricetierGridViewApi === "loading") {
    if (actionType === "pricetier") {
      return Array.apply(0, Array(2)).map(function (x, i) {
        return (
          <a key={i} className="box-image-cover cursor-pointer placeholder-glow" id="addstaff-member-link">
            <div className="tabs-image">
              <span className="placeholder rounded-circle" style={{ width: "130px", height: "130px" }}></span>
            </div>
            <div className="image-content">
              <h5 className="placeholder w-100"></h5>
            </div>
          </a>
        );
      });
    }
  }

  if (categoryApiStatus && categoryApiStatus.categoryListViewApi === "loading") {
    if (actionType === "categories") {
      return (
        <div className="services-table" id="scrollableServiceListView">
          <div className="table-responsive bg-white table-shadow h-100">
            <table className="table mb-0 table-hover">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="service_table_header" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="services-table-data">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  if (serviceApiStatus && serviceApiStatus.serviceListViewApi === "loading") {
    if (actionType === "services") {
      return (
        <div className="services-table" id="scrollableServiceListView">
          <div className="table-responsive bg-white table-shadow h-100">
            <table className="table mb-0 table-hover">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="service_table_header" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="services-table-data">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  if (membershipApiStatus && membershipApiStatus.MembershipGridViewApi === "loading") {
    if (actionType === "membership") {
      return (
        <div className="services-table" id="scrollableServiceListView">
          <div className="table-responsive bg-white table-shadow h-100">
            <table className="table mb-0 table-hover">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="service_table_header" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="services-table-data">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  if (membershipApiStatus && membershipApiStatus.MembershipServiceApi === "loading") {
    if (actionType === "membership-services") {
      return (
        <div className="accordion" id="accordionExample">
          {Array.apply(0, Array(10)).map(function (x, i) {
            return (
              <div className="accordion-item mb-md-4 mb-3 placeholder-glow" key={i}>
                <h2 className="placeholder w-100 mb-0"></h2>
              </div>
            );
          })}
        </div>
      );
    }
  }

  if (appointmentcampaignApiStatus && appointmentcampaignApiStatus.AppointmentCampaignListViewApi === "loading") {
    if (actionType === "appointmentcampaign") {
      return (
        <div className="row">
          <div className="col-md-8 m-auto text-end pt-5 pb-4">
            <a className="btn btn-primary add-service me-md-3 me-1 add-new-btn px-xs-4 placeholder" style={{ width: "100px" }}></a>
          </div>
          <div className="col-md-8 m-auto">
            {Array.apply(0, Array(5)).map(function (x, i) {
              return (
                <div className="col-12" key={i}>
                  <div className="box-image-cover w-100 mx-0 p-md-4 p-3 mb-md-4 mb-3 text-start placeholder-glow">
                    <div className="row align-items-center">
                      <div className="col-xxl-9 col-md-8 mb-md-0 mb-2">
                        <div className="d-flex align-items-center">
                          <div className="text-center">
                            <span className="placeholder rounded-circle" style={{ width: "30px", height: "30px" }}></span>
                          </div>
                          <div className="ps-3  col-md-12">
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-12"></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-md-4 ps-md-0 d-flex justify-content-between align-items-center">
                        <div className="form-check form-switch mb-0">
                          <span className="placeholder" style={{ width: "100px" }}></span>
                        </div>
                        <span className="placeholder btn" style={{ width: "50px" }}></span>
                        <span className="placeholder btn" style={{ width: "50px" }}></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  if (birthdaycampaignApiStatus && birthdaycampaignApiStatus.BirthdayCampaignListViewApi === "loading") {
    if (actionType === "birthdaycampaign") {
      return (
        <div className="row">
          <div className="col-md-8 m-auto text-end pt-5 pb-4">
            <a className="btn btn-primary add-service me-md-3 me-1 add-new-btn px-xs-4 placeholder" style={{ width: "100px" }}></a>
          </div>
          <div className="col-md-8 m-auto">
            {Array.apply(0, Array(5)).map(function (x, i) {
              return (
                <div className="col-12" key={i}>
                  <div className="box-image-cover w-100 mx-0 p-md-4 p-3 mb-md-4 mb-3 text-start placeholder-glow">
                    <div className="row align-items-center">
                      <div className="col-xxl-9 col-md-8 mb-md-0 mb-2">
                        <div className="d-flex align-items-center">
                          <div className="text-center">
                            <span className="placeholder rounded-circle" style={{ width: "30px", height: "30px" }}></span>
                          </div>
                          <div className="ps-3  col-md-12">
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-12"></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-md-4 ps-md-0 d-flex justify-content-between align-items-center">
                        <div className="form-check form-switch mb-0">
                          <span className="placeholder" style={{ width: "100px" }}></span>
                        </div>
                        <span className="placeholder btn" style={{ width: "50px" }}></span>
                        <span className="placeholder btn" style={{ width: "50px" }}></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (reviewcampaignApiStatus && reviewcampaignApiStatus.ReviewCampaignListViewApi === "loading") {
      if (actionType === "reviewcampaign") {
        return (
          <div className="row">
            <div className="col-md-8 m-auto text-end pt-5 pb-4">
              <a className="btn btn-primary add-service me-md-3 me-1 add-new-btn px-xs-4 placeholder" style={{ width: "100px" }}></a>
            </div>
            <div className="col-md-8 m-auto">
              {Array.apply(0, Array(5)).map(function (x, i) {
                return (
                  <div className="col-12" key={i}>
                    <div className="box-image-cover w-100 mx-0 p-md-4 p-3 mb-md-4 mb-3 text-start placeholder-glow">
                      <div className="row align-items-center">
                        <div className="col-xxl-9 col-md-8 mb-md-0 mb-2">
                          <div className="d-flex align-items-center">
                            <div className="text-center">
                              <span className="placeholder rounded-circle" style={{ width: "30px", height: "30px" }}></span>
                            </div>
                            <div className="ps-3  col-md-12">
                              <span className="placeholder col-12"></span>
                              <span className="placeholder col-12"></span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-md-4 ps-md-0 d-flex justify-content-between align-items-center">
                          <div className="form-check form-switch mb-0">
                            <span className="placeholder" style={{ width: "100px" }}></span>
                          </div>
                          <span className="placeholder btn" style={{ width: "50px" }}></span>
                          <span className="placeholder btn" style={{ width: "50px" }}></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
    }

    if (bookingApiStatus && bookingApiStatus.BookingApi === "loading") {
      if (actionType === "bookingview") {
        return (
          <div className="nav flex-column nav-pills acsetup-left online-booking-wrapper placeholder-glow" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <div className="container overflow-auto">
              <div className="row mb-4">
                <div className="col-lg-8 mx-auto">
                  <div className="booking-description text-center mb-4">
                    <p>
                      <span className="placeholder" style={{ width: "100px" }}></span> <span className="placeholder" style={{ width: "100px" }}></span>
                    </p>
                  </div>
                  <div className="online-booking-content bg-white">
                    <div className="booking-logo d-flex justify-content-center">
                      <span className="placeholder rounded-circle" style={{ width: "136px", height: "136px" }}></span>
                    </div>
                    <div className="select-option-wrapper">
                      <div className="please-sele">
                        <a href="#" className="select-btn">
                          <span className="placeholder" style={{ width: "100px" }}></span>
                        </a>
                      </div>
                      <div className="items-list">
                        <ul className="m-0 p-0">
                          {Array.apply(0, Array(3)).map(function (x, i) {
                            return (
                              <li className="items-option" key={i}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <span className="placeholder col-12"></span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="power-be mt-3 text-center">
                    <span className="placeholder col-4 m-auto"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
  if (AcademyApiStatus && AcademyApiStatus.AcademyCategoryGridViewApi === "loading") {
    if (actionType === "acadamyview") {
      return (
        <div className="container">
          <div className="row px-5">
            {Array.apply(0, Array(8)).map((_, i) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={i}>
                <div className="card" aria-hidden="true">
                  <span className="placeholder" style={{ width: "100%", height: "150px" }}></span>
                  <div className="card-body">
                    <h5 className="card-title placeholder-glow text-center">
                      <span className="placeholder" style={{ width: "50%" }}></span>
                    </h5>
                    <p className="card-text placeholder-glow text-center">
                      <span className="placeholder" style={{ width: "40%" }}></span>
                      <span className="placeholder" style={{ width: "60%" }}></span>
                      <span className="placeholder" style={{ width: "80%" }}></span>
                      <span className="placeholder" style={{ width: "50%" }}></span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
  if (subAcademyApiStatus && subAcademyApiStatus.AcademyArticleGridViewApi === "loading") {
    if (actionType === "subacademyview") {
      return (
        <div className="container">
          <div className="row px-5">
            {Array.apply(0, Array(8)).map((_, i) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4 px-4" key={i}>
                <div className="card" aria-hidden="true">
                  <span className="placeholder" style={{ width: "100%", height: "150px" }}></span>
                  <div className="card-body">
                    <h5 className="card-title placeholder-glow text-center">
                      <span className="placeholder" style={{ width: "50%" }}></span>
                    </h5>
                    <p className="card-text placeholder-glow text-center">
                      <span className="placeholder" style={{ width: "40%" }}></span>
                      <span className="placeholder" style={{ width: "60%" }}></span>
                      <span className="placeholder" style={{ width: "80%" }}></span>
                      <span className="placeholder" style={{ width: "50%" }}></span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
  if (everydaymarketApiStatus && everydaymarketApiStatus.everydaymarketCategoryViewApi === "loading") {
    if (actionType === "everydaymarketview") {
      return (
        <div className="row">
          {Array.apply(0, Array(8)).map(function (x, i) {
            return (
              <div className="marketing-box-cover text-center" key={i}>
                <div className="row">
                  <div className="col-12">
                    <span className="placeholder rounded-circle mb-2" style={{ width: "80px", height: "80px" }}></span>
                  </div>
                  <div className="col-12">
                    <span className="placeholder w-100"></span>
                  </div>
                  <div className="col-12">
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
  if (subeverydaymarketApiStatus && subeverydaymarketApiStatus.everydaymarketingcampaignApi === "loading") {
    if (actionType === "subeverydaymarketview") {
      return (
        <div className="row">
          {Array.from({ length: 4 }).map((_, i) => (
            <div className="col-md-3 col-sm-6 text-center" key={i}>
              <div className="mx-0 p-md-4 p-3 mb-md-4 mb-3 placeholder-glow">
                <div className="mb-1">
                  <span className="placeholder rounded" style={{ width: "100%", height: "200px" }}></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }
  if (DashboardApiStatus && DashboardApiStatus.UpcomingAppointmentListViewApi === "loading") {
    if (actionType === "dashboardUpcomingAppointment") {
      return (
        <ul className="list-unstyled mb-0 px-2">
          {Array.apply(0, Array(10)).map(function (x, i) {
            return (
              <li className="user-box d-flex py-lg-4 py-3 placeholder-glow" key={i}>
                <div className="d-flex align-items-center pe-2">
                  <div className="user-initial me-xxl-3 me-2 text-uppercase">
                    <span className="placeholder rounded-circle" style={{ width: "30px", height: "30px" }}></span>
                  </div>
                  <div className="user-id">
                    <h6 className="mb-1 fw-semibold">
                      <span className="placeholder" style={{ width: "200px" }}></span>
                    </h6>
                    <div className="lh-sm time">
                      <span className="placeholder" style={{ width: "200px" }}></span>
                      <br />
                      <span className="placeholder" style={{ width: "200px" }}></span> - <span className="placeholder" style={{ width: "200px" }}></span>
                      <div className="dashboard-upcoming-btn">
                        <span className="placeholder btn" style={{ width: "200px" }}></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end ms-auto">
                  <span className="placeholder" style={{ width: "20px" }}></span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  }
  if (ProductApiStatus && ProductApiStatus.productListViewApi === "loading") {
    if (actionType === "product") {
      return (
        <div className="services-table" id="scrollableServiceListView">
          <div className="table-responsive bg-white table-shadow h-100">
            <table className="table mb-0 table-hover">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="service_table_header" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="services-table-data">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  if (SaleInvoiceApiStatus && SaleInvoiceApiStatus.CreateInvoiceListViewApi === "loading") {
    if (actionType === "createinvoicelist") {
      return (
        <div className="services-table" id="scrollableServiceListView">
          <div className="table-responsive bg-white table-shadow h-100">
            <table className="table mb-0 table-hover">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="service_table_header" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="services-table-data">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  if (SaleInvoiceApiStatus && SaleInvoiceApiStatus.InvoiceListViewApi === "loading") {
    if (actionType === "viewinvoicelist") {
      return (
        <div className="services-table" id="scrollableServiceListView">
          <div className="table-responsive bg-white table-shadow h-100">
            <table className="table mb-0 table-hover">
              <thead>
                <tr className="placeholder-glow">
                  {Array.apply(0, Array(6)).map(function (y, j) {
                    return (
                      <th className="service_table_header" key={j}>
                        <span className="placeholder w-100"></span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="services-table-data">
                {Array.apply(0, Array(50)).map(function (x, i) {
                  return (
                    <tr className="placeholder-glow" key={i}>
                      {Array.apply(0, Array(6)).map(function (y, j) {
                        return (
                          <td className="text-center" align="center" key={i + "" + j}>
                            <span className="placeholder w-100"></span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  if (clientproductApiStatus && clientproductApiStatus.ClientproductApi === "loading") {
    if (actionType === "clientproductview") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box placeholder-glow mb-2" key={x}>
                <div className="row align-items-center justify-contents-start px-4">
                  <div className="col-3">
                    <span className="placeholder rounded-circle" style={{ width: "60px", height: "60px" }}></span>
                  </div>
                  <div className="col-9">
                    <span className="placeholder w-100 mb-2"></span>
                    <span className="placeholder w-100 mb-2"></span>
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (clientappointmentApiStatus && clientappointmentApiStatus.ClientAppointmentListViewApi === "loading") {
    if (actionType === "clientappointmentlistview") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box placeholder-glow mb-2" key={x}>
                <div className="row align-items-center justify-contents-center px-4">
                  <div className="col-12 text-center">
                    <span className="placeholder w-100 mb-2"></span>
                    <span className="placeholder w-100 mb-2"></span>
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (clientinvoiceApiStatus && clientinvoiceApiStatus.ClientInvoiceListViewApi === "loading") {
    if (actionType === "clientinvoiceview") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box placeholder-glow mb-2" key={x}>
                <div className="row align-items-center justify-contents-center px-4">
                  <div className="col-12 text-center">
                    <div className="row justify-content-between">
                      <div className="col-6">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <span className="placeholder w-100 mb-2"></span>
                    <div className="row justify-content-between">
                      <div className="col-4">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (clientvoucherApiStatus && clientvoucherApiStatus.ClientVoucherListViewApi === "loading") {
    if (actionType === "clientvoucherview") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box-pink placeholder-glow mb-4" key={x}>
                <div className="row align-items-center justify-contents-center px-4">
                  <div className="col-12 text-center">
                    <div className="row justify-content-between">
                      <div className="col-5">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <span className="placeholder w-100 mb-2"></span>
                    <span className="placeholder w-100 mb-2"></span>
                    <div className="row justify-content-between">
                      <div className="col-8">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-8">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (clientsubscriptionApiStatus && clientsubscriptionApiStatus.ClientSubscriptionListViewApi === "loading") {
    if (actionType === "clientsubscriptionview") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box-pink placeholder-glow mb-4" key={x}>
                <div className="row align-items-center justify-contents-center px-4">
                  <div className="col-12 text-center">
                    <div className="row justify-content-between">
                      <div className="col-6">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-6">
                            <span className="placeholder w-100 mb-2"></span>
                          </div>
                          <div className="col-6">
                            <span className="placeholder w-100 mb-2"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-3">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-3">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-3">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-4">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-4">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (clientmembershipApiStatus && clientmembershipApiStatus.ClientMembershipListViewApi === "loading") {
    if (actionType === "clientmembershipview") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box-pink placeholder-glow mb-4" key={x}>
                <div className="row align-items-center justify-contents-center px-4">
                  <div className="col-12 text-center">
                    <span className="placeholder w-100 mb-2"></span>
                    <div className="row justify-content-between">
                      <div className="col-8">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-8">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-8">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                      <div className="col-2">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (clientformApiStatus && clientformApiStatus.ClientformApi === "loading") {
    if (actionType === "clientconsultationforms") {
      return (
        <>
          {Array.apply(0, Array(7)).map(function (x, i) {
            return (
              <div className="event-box-pink placeholder-glow mb-4" key={x}>
                <div className="row align-items-center justify-contents-center">
                  <div className="col-10 text-center">
                    <div className="row justify-content-between">
                      <div className="col-10">
                        <span className="placeholder w-100 mb-2"></span>
                      </div>
                    </div>
                    <div className="row justify-content-between">
                      <div className="col-10">
                        <span className="placeholder placeholder-glow w-100 mb-2"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 text-center">
                    <span className="placeholder w-100 mb-2 py-2"></span>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      );
    }
  }
  if (supplierGridViewApiStatus && supplierGridViewApiStatus.supplierGridViewApi === "loading") {
    if (actionType === "supplier") {
      return Array.apply(0, Array(50)).map(function (x, i) {
        return (
          <a key={i} className="box-image-cover cursor-pointer placeholder-glow" id="addstaff-member-link">
            <div className="tabs-image">
              <span className="placeholder rounded-circle" style={{ width: "130px", height: "130px" }}></span>
            </div>
            <div className="image-content">
              <h5 className="placeholder w-100"></h5>
            </div>
          </a>
        );
      });
    }
  }
  if (clientnotificationApiStatus && clientnotificationApiStatus.NotifyDetailListViewApi === "loading") {
    if (actionType === "clientnotificationLoading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-10 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (SaleInvoiceApiStatus && SaleInvoiceApiStatus.SaleServiceApi === "loading") {
    if (actionType === "sale_service_api_loading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-12 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  if (subscriptionApiStatus && subscriptionApiStatus.SubscriptionServiceApi !== "loading") {
    if (actionType === "subscription_service_loading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-12 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (memberShipOption && memberShipOption.BookingMembershipOptions === "loading") {
    if (actionType === "BookingMembershipOptions") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-12 mb-3" key={i}>
            <span className="placeholder w-100 mb-2"></span>
          </div>
        );
      });
    }
  }
 
  if (SaleInvoiceApiStatus && SaleInvoiceApiStatus.SaleProductApi === "loading") {
    if (actionType === "sale_product_api_loading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-12 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (SaleInvoiceApiStatus && SaleInvoiceApiStatus.SaleMembershipApi === "loading") {
    if (actionType === "sale_membership_api_loading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-12 mx-auto" key={i}>
            <div className="row">
              <div className="col-6">
                <div className="box-image-cover w-100  mx-0 p-4 mb-3 text-start placeholder-glow">
                  <div>
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
                  <div >
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (SaleInvoiceApiStatus && SaleInvoiceApiStatus.SaleSubscriptionApi === "loading") {
    if (actionType === "sale_subscription_api_loading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-12 mx-auto" key={i}>
            <div className="row">
              <div className="col-6">
                <div className="box-image-cover w-100  mx-0 p-4 mb-3 text-start placeholder-glow">
                  <div>
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
                  <div >
                    <span className="placeholder w-100"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (cancellationApiStatus && cancellationApiStatus.CancellationreasonListViewApi === "loading") {
    if (actionType === "cancellationlistloading") {
      return Array.apply(0, Array(6)).map(function (x, i) {
        return (
          <div className="col-xl-10 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (consultationFormApiStatus && consultationFormApiStatus.FormListViewApi === "loading") {
    if (actionType === "consultationlistloading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-10 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (chatApiStatus && chatApiStatus.ClientchatGridViewApi === "loading") {
    if (actionType === "chatapiloading") {
      return Array.apply(0, Array(30)).map(function (_, i) {
        // Toggle sender_receiver between 0 and 1 after every two rows
        let sender_receiver = i % 2 === 0 ? 0 : 1;

        return (
          <div
            key={i}
            className={`message-box ${sender_receiver === 1 ? "right" : "left"} placeholder-glow`}
          >
            <div
              className={`message ${sender_receiver === 1 ? "right" : "left"}`}
              style={{
                backgroundColor: sender_receiver === 1 ? "#f9f6f4" : "#feeef3",
                width: "50%",
                height: "27px"
              }}
            >

            </div>
          </div>
        );
      });
    }
  }
  if (closedateApiStatus && closedateApiStatus.CloseddateListViewApi === "loading") {
    if (actionType === "closeddatelistloading") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-10 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (subscriptionApiStatus && subscriptionApiStatus.SubscriptionServiceCartApi === "loading") {
    if (actionType === "SubscriptionListLoads") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-xl-10 mx-auto" key={i}>
            <div className="box-image-cover w-100 mx-0 p-4 mb-3 text-start placeholder-glow">
              <div className="row">
                <div className="col-12">
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100"></span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  if (bookinglinkcategoryApiStatus && bookinglinkcategoryApiStatus.BookingCategoryOptions === "loading") {
    if (actionType === "categoriesbookingLink") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-12 mb-3" key={i}>
            <span className="placeholder w-100 mb-2"></span>
          </div>
        );
      });
    }
  }
  if (bookinglinkcategoryApiStatus && bookinglinkcategoryApiStatus.BookingServiceOptions === "loading") {
    if (actionType === "serviceapibookingLink") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-12 mb-3 placeholder-glow" key={i}>
            <span className="placeholder w-100 mb-2"></span>
          </div>
        );
      });
    }
  }
  if (bookinglinkcategoryApiStatus && bookinglinkcategoryApiStatus.BookingStaffOptions === "loading") {
    if (actionType === "staffapibookingLink") {
      return Array.apply(0, Array(5)).map(function (x, i) {
        return (
          <div className="col-12 mb-3 placeholder-glow" key={i}>
            <span className="placeholder w-100 mb-2"></span>
          </div>
        );
      });
    }
  }
  if (notificationApiStatus && notificationApiStatus.NotificationListApi === "loading") {
    if (actionType === "notificationslistview") {
      return Array.apply(0, Array(20)).map(function (x, i) {
        const backgroundColor = i % 2 === 0 ? '#feeef3' : '#f9f6f4';
        return (
          <div className="box-image-cover notification-boxs w-100 mx-0 py-md-4 py-3 text-start" style={{ background: backgroundColor }} key={i}>
            <div className="col-12 placeholder-glow">
              <span className="placeholder w-100 mb-2"></span>
            </div>
          </div>
        );
      });
    }
  }

  if (bookinglinkcategoryApiStatus && bookinglinkcategoryApiStatus.BookingStaffAvailableTimeApi === "loading" || bookinglinkcategoryApiStatus.BookingNextResourceItemTimeApi === "loading") {
    if (actionType === "availabletimeBookingLink") {
      return Array.apply(0, Array(7)).map(function (x, i) {
        const backgroundColor = i % 2 === 0 ? '#feeef3' : '#f9f6f4';
        return (
          <div className="box-image-cover notification-boxs w-100 mx-0 py-md-2 py-1 text-start mb-2" style={{ background: backgroundColor }} key={i}>
            <div className="col-12 placeholder-glow">
              <span className="placeholder w-100"></span>
            </div>
          </div>
        );
      });
    }
  }
  // if (closedateApiStatus && closedateApiStatus.CloseddateListViewApi === "loading") {
  if (actionType === "isStaffAccessLoading") {
    return Array.apply(0, Array(10)).map(function (x, i) {
      return (
        <div className="border-bottom-services-preview" key={i}>
          <div className="row px-3">
            <div className="col-md-6 mb-md-0 mb-3 text-md-start text-center">
              <span className="placeholder w-100 mb-2"></span>
              <span className="placeholder w-100 mb-2"></span>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-start align-items-start">
                <div className="col-2">
                  <span className="placeholder w-75 mb-2"></span>
                </div>
                <div className="col-6">
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                  <span className="placeholder w-100 mb-2"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    );
  }
  if (VoucherGridViewApiStatus && VoucherGridViewApiStatus.VoucherGridViewApi === "loading") {
    if (actionType === "voucher") {
      return Array.apply(0, Array(50)).map(function (x, i) {
        return (
          <a key={i} className="box-image-cover cursor-pointer placeholder-glow" id="addstaff-member-link">
            <div className="tabs-image">
              <span className="placeholder rounded-circle" style={{ width: "130px", height: "130px" }}></span>
            </div>
            <div className="image-content">
              <h5 className="placeholder w-100"></h5>
            </div>
          </a>
        );
      });
    }
  }
  return null;
};

ApiStatus.propTypes = {
  actionType: PropTypes.string.isRequired,
};

export default ApiStatus;