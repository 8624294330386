import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// validation Formik
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
// import config from "../../config";
import yupconfig from "../../../../yupconfig";

import PropTypes from "prop-types";
import { ClientchatGridViewApi, clientchatStoreApi, closeAddChatForm } from "store/slices/clientChatSlice";
import { sweatalert } from "component/Sweatalert2";
import { InputField, TextareaField } from "component/form/Field";
import customToast from "component/Toastr";
import BarLoader from "react-spinners/BarLoader";
import { OpenCustomAlerts } from "store/slices/commonSlice";

const ClientChatCreate = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const detail = useSelector((state) => state.client.isDetailData);

  const initialValues = {
    client_id: "",
    salon_id: "",
    phone_number: "",
    country_code: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(t("Message is required")),
  });
  yupconfig();

  const handleClientChatSubmit = (values, { setErrors, setStatus, setSubmitting, setFieldValue, resetForm,setTouched }) => {
    setLoading(true);
    try {
      dispatch(clientchatStoreApi(values)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          setFieldValue('message', '');    // Clear the 'message' field
          setTouched({ message: false });  // Mark 'message' as not touched
          setErrors({ message: '' });       // Clear the 'message' error
          setStatus({ success: true });
          setLoading(false);
          customToast.success("Message Successfully Sent");
        } else if (action.meta.requestStatus === "rejected") {
          const status = action.payload && action.payload.status;
          const errors = action.payload && action.payload.message && action.payload.message.errors;
          const response = action.payload && action.payload.message && action.payload.message;
          if (status === 422) {
            dispatch(
              OpenCustomAlerts({
                isOpen: "open",
                errors: (
                  <>
                    <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                    <ul className="list-unstyled">
                      {errors &&
                        Object.keys(errors).map((a, n) => {
                          if (errors[a].length > 0) {
                            return (
                              <>
                                {errors[a].map((as, ns) => {
                                  return (
                                    <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                      {as}
                                    </li>
                                  );
                                })}
                              </>
                            );
                          }
                        })}
                    </ul>
                  </>
                )
              })
            );
            setErrors(errors);
          }
          setLoading(false);
          setStatus({ success: false });
          setSubmitting(false);
        }
      });
    } catch (err) {
      setErrors(err.message);
      setStatus({ success: false });
      setLoading(false);
    }
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClientChatSubmit}>
        {(formik) => {

          useEffect(() => {
            if (detail) {
              formik.setFieldValue('client_id', detail.id ? detail.id : "", false);
              formik.setFieldValue('salon_id', detail.salon_id ? detail.salon_id : "", false);
              formik.setFieldValue('phone_number', detail.phone_number ? detail.phone_number : "", false);
              formik.setFieldValue('country_code', detail.country_code ? detail.country_code : "", false);
            }
          }, [detail])

          const handleTextareaKeyDown = (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              // Optionally, you can add a new line to the textarea manually
              const { name, value } = e.target;
              formik.setFieldValue(name, `${value}\n`);
            }
          };

          return (
            <div className="my-3 px-3">
              <form noValidate onSubmit={formik.handleSubmit} className="clinetchatform d-flex align-items-center justify-content-center w-100">
                <div className="w-100">
                  <div className="w-100 me-2">
                    <Field
                      as='textarea'
                      name="message"
                      className='form-control client-chat-textarea'
                      placeholder='Type your messages here...'
                      value={formik.values.message}
                      controlId="clientForm-message"
                      rows={4}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          // Optionally, you can add a new line to the textarea manually
                          const { name, value } = e.target;
                          formik.setFieldValue(name, `${value}\n`);
                        }
                      }}
                    />
                  </div>
                  <ErrorMessage name="message" component="div" className="text-danger" />
                  <div>
                    <button type="submit" className={`btn btn-primary add-new-btn px-lg-4 fw-bold cursor-pointer w-100 ${formik.errors && formik.errors.message ? "mt-1" : "mt-3"}`} disabled={loading}>
                      {loading ? (<span className="spinner-border spinner-border-sm"></span>) : (t("Send"))}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

ClientChatCreate.propTypes = {
  label: PropTypes.string,
  client_id: PropTypes.number,
};

export default ClientChatCreate;
