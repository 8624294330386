import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bookingApiController from "services/booking.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const checkStaffAvailabilityApi = createAsyncThunk(
  "staffAvailability/check",
  async (formValues, thunkAPI) => {
    try {
      const responseData = await bookingApiController
        .checkStaffAvailability(formValues)
        .then((response) => HandleResponse(thunkAPI, response, "staffAvailability"))
        .catch((error) => HandleError(thunkAPI, error, "staffAvailability"));
      return responseData;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  availabilityData: null,
  isLoading: false,
  error: null,
  status: "idle",
};

const staffAvailabilitySlice = createSlice({
  name: "staffAvailability",
  initialState,
  reducers: {
    resetAvailability: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkStaffAvailabilityApi.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(checkStaffAvailabilityApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "succeeded";
        state.availabilityData = action.payload;
      })
      .addCase(checkStaffAvailabilityApi.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAvailability } = staffAvailabilitySlice.actions;
export default staffAvailabilitySlice.reducer; 