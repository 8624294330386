import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  const action = "afterlogin/clientchat/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);

  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

// const update = (values) => {
//   const auth = store.getState().auth;
//   const auth_key = auth.user.auth_key;
//   const formData = new FormData();
//   for (let value in values) {
//     formData.append(value, values[value]);
//   }
//   const action = "afterlogin/clientchat/update/" + values.id;
//   formData.append("auth_key", auth_key);
//   formData.append("action", action);
//   formData.append("client_id", values.client_id);
//   return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
// };

const view = (values) => {
  // Get the authentication token from the Redux store
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  // Extract parameters from the 'values' object
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  let pagination = true;

  // Check if pagination should be enabled based on the 'values' object
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }

  // Construct the 'action' URL based on the 'page' parameter
  const action = page ? `afterlogin/clientchat/view?page=${page}` : `afterlogin/clientchat/view`;

  // Create the 'data' object to be sent in the request
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination,
    id: values && values.id ? values.id : "",
    client_id: values && values.client_id ? values.client_id : "",
    result: result,
  };
  
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};


const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/clientchat/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const clientchatApiController = {
  create,
  // update,
  view,
  deleted,
};
export default clientchatApiController;
