import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  
  const formData = new FormData();
  for (let value in values) {
    if (["resource_item"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/resource/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);

  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["resource_item"].includes(value) && values[value]) {
      formData.append(value, JSON.stringify(values[value]));
    }else{
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/resource/update/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  formData.append("user_id", values.user_id);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};



const view = (values) => {
  // Get the authentication token from the Redux store
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;

  // Extract parameters from the 'values' object
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const user_id = values && values.user_id ? values.user_id  :"";
  const label = values && values.label ? values.label  :"";
  let q = values && values.q ? values.q : "";
  let pagination = true;

  // Check if pagination should be enabled based on the 'values' object
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }

  // Construct the 'action' URL based on the 'page' parameter
  const action = page ? `afterlogin/resource/view?page=${page}?q=${q}` : `afterlogin/resource/view?q=${q}`;

  // Create the 'data' object to be sent in the request
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination,
    apicall:"suggetion",
    id: values && values.id ? values.id : "",
    user_id: user_id,
    label: label,
    result: result,
  };
  
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const resourcelist = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const getRow = values && values.getRow ? values.getRow : "";
  let pagination = false;
  
  const action = `afterlogin/staff/view`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: false, //true or false
    id: "",
    result: result, //business_name,owner_name
    pagetype: pagetype,
    getRow: getRow,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const addonservices = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  let resource_id = values && values.resource_id ? values.resource_id : "";
  const action = page ? `afterlogin/resource/services?page=${page}&resource_id=${resource_id}` : `afterlogin/resource/services?resource_id=${resource_id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(next_page_url ? `${next_page_url}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/resource/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const resourceApiController = {
  create,
  update,
  view,
  deleted,
  resourcelist,
  addonservices
};
export default resourceApiController;
