import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { Helmet } from "react-helmet-async";
import { CustompagesApi } from "store/slices/custompagesSlice";
import { SettingsApi } from "store/slices/settingSlice";
// ==============================|| MINIMAL LAYOUT ||============================== //

const CustomLayout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const bookinpath = location && location.pathname.split("/");

  const arrayofPath = ["booking", "consultationforms"];

  if (bookinpath.length > 0 && (bookinpath.includes("booking") || bookinpath.includes("online-booking"))) {
    return (
      <>
        <Helmet>
          {/* <link rel="stylesheet" href={config.baseUrl + "/css/style.css"} /> */}
          <link rel="stylesheet" href={"https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"} />
        </Helmet>
        <main>
          <div className="booking widget">
            <Outlet />
          </div>
        </main>
      </>
    );
  } else if (bookinpath.length > 0 && bookinpath.includes("consultationforms")) {
    return (
      <>
        <Helmet>
          {/* <link rel="stylesheet" href={config.baseUrl + "/css/style.css"} /> */}
          <link rel="stylesheet" href={"https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"} />
        </Helmet>
        <main>
          <div className="consultationforms">
            <Outlet />
          </div>
        </main>
      </>
    );
  }else if (bookinpath.length > 0 && bookinpath.includes("subscription-invoice")) {
    return (
      <>
        <Helmet>
          {/* <link rel="stylesheet" href={config.baseUrl + "/css/style.css"} /> */}
          <link rel="stylesheet" href={"https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"} />
        </Helmet>
        <main>
          <div className="subscription-invoice">
            <Outlet />
          </div>
        </main>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          {/* <link rel="stylesheet" href={config.baseUrl + "/css/style.css"} /> */}
          <link rel="stylesheet" href={"https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"} />
        </Helmet>
        <main>
          {
            <div className="d-flex align-items-center justify-content-center vh-100">
              <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                <p className="lead">
                  The page you’re looking for doesn’t exist.
                </p>
                <a className="btn btn-primary" onClick={() => navigate(config.basePath + "/calendar")}>Go Home</a>
              </div>
            </div>
          }
        </main>
      </>
    );
  }
};

export default CustomLayout;
